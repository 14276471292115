import { Button, Form } from "antd";
import styled from "styled-components";
import { ORANGE } from "../../utils/colors";

export const StyledContainer = styled.div`
  align-items: center;
  background: linear-gradient(137.74deg, #ffffff 19.05%, #fff6ee 75.22%);
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100vh;
`;

export const StyledLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 100px;
  margin: 0 10%;
`;

export const StyledDescription = styled.h1`
  font-size: 36px;
  font-weight: 700;
`;

export const StyledOrange = styled.span`
  color: ${ORANGE};
`;

export const StyledFormContainer = styled.div`
  align-items: center;
  background: white;
  border-radius: 16px;
  box-shadow: 0px 12px 48px -5px rgba(46, 50, 52, 0.08);
  display: flex;
  flex-direction: column;
  padding: 24px;
`;

export const StyledForm = styled(Form)`
  width: 100%;
`;

export const StyledButton = styled(Button)`
  width: 100%;
`;
