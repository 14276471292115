import { useContext } from "react";
import { AlertContext } from "../../store/mobx/Alert";

interface Props {
  url: string;
  name: string;
}

function DropboxSaver(props: Props) {
  const { setAlert } = useContext(AlertContext);

  return (
    <button
      className="dropbox-saver dropbox-dropin-btn dropbox-dropin-default"
      onClick={() =>
        // @ts-ignore
        window.Dropbox.save(props.url, props.name, {
          success: function () {
            setAlert({
              msg: "Success! Files saved to your Dropbox.",
              type: "success",
            });
          },
          error: function () {
            setAlert({
              msg: "Error! Please try again later.",
              type: "error",
            });
          },
          progress: function (progress) {
            if (progress === 0) {
              setAlert(
                {
                  msg: "Download in progress...",
                  type: "warning",
                },
                false
              );
            }
          },
        })
      }
      style={{ whiteSpace: "nowrap" }}
    >
      <span className="dropin-btn-status"></span>
      Save to Dropbox
    </button>
  );
}

export default DropboxSaver;
