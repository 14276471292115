import { Col, Skeleton } from "antd";
import { useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";

import DocumentsToolbar from "./DocumentsToolbar";
import { StyledFixedContainer, StyledRow } from "./Documents.styles";
import DocumentViewer from "../DocumentViewer";
import { DashboardStoreContext } from "../../store/mobx/Dashboard";
import Case from "../Case";
import { getCases } from "../../actions/cases";

const Documents = () => {
  const { requestingCases, selectedDocument } = useContext(
    DashboardStoreContext
  );

  useEffect(() => {
    const fetchCases = async () => {
      await getCases();
    };

    fetchCases();
  }, []);

  if (requestingCases) {
    return <Skeleton active style={{ marginRight: "25px", width: "auto" }} />;
  }

  return (
    <>
      <DocumentsToolbar />
      <StyledRow>
        <Col span={11}>
          <StyledFixedContainer>
            <Case />
          </StyledFixedContainer>
        </Col>
        <Col span={13}>
          <DocumentViewer s3Url={selectedDocument?.s3Url || ""} />
        </Col>
      </StyledRow>
    </>
  );
};

export default observer(Documents);
