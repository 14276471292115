import AuthStore, { AuthContext } from "../../store/mobx/AuthStore";

interface Props {
  children: React.ReactNode;
}

const AuthProvider = ({ children }: Props) => {
  return (
    <AuthContext.Provider value={new AuthStore()}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
