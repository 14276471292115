import AlertStore, { AlertContext } from "../../store/mobx/Alert";

interface Props {
  children: React.ReactNode;
}

const AlertProvider = ({ children }: Props) => {
  return (
    <AlertContext.Provider value={new AlertStore()}>
      {children}
    </AlertContext.Provider>
  );
};

export default AlertProvider;
