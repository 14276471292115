import { SelectedCase } from "./../../types.d";
import { ENDPOINTS } from "../../utils/constants";
import dashboardStore from "../../store/mobx/Dashboard";
import API_CALL from "../api";

export const getCase = async (caseId: string): Promise<SelectedCase> => {
  const { setRequesting } = dashboardStore;
  try {
    setRequesting("case", true);
    const { data } = await API_CALL({
      method: "GET",
      path: `${ENDPOINTS.cases}/${caseId}`,
    });

    setRequesting("case", false);
    return data;
  } catch (error) {
    setRequesting("case", false);
    throw error;
  }
};

type TempSaveReturn = {
  version: number;
};

export const tempSaveCase = async (
  caseId?: string,
  selectedCase?: SelectedCase
): Promise<TempSaveReturn | undefined> => {
  if (!caseId || !selectedCase) return;
  const { setRequesting } = dashboardStore;

  try {
    setRequesting("case", true);
    const { data } = await API_CALL({
      method: "POST",
      path: `${ENDPOINTS.cases}/${caseId}/fields`,
      body: {
        qa: selectedCase.fields.qa,
        qaFieldsDocumentIds: selectedCase.fields.qaFieldsDocumentIds,
        version: selectedCase.version,
      },
    });

    setRequesting("case", false);
    return data;
  } catch (error) {
    setRequesting("case", false);
    throw error;
  }
};

export const completeReviewReq = async ({
  caseId,
  version,
}: Partial<SelectedCase>) => {
  const { setRequesting } = dashboardStore;
  try {
    setRequesting("case", true);
    const response = await API_CALL({
      method: "POST",
      path: `${ENDPOINTS.cases}/${caseId}/complete-review`,
      queryParams: { version },
    });

    setRequesting("case", false);
    return response;
  } catch (error) {
    setRequesting("case", false);
    throw error;
  }
};
