import styled from "styled-components";
import { Row } from "antd";

export const StyledRow = styled(Row)`
  height: 100%;
`;

export const StyledFixedContainer = styled.div`
  height: 100%;
  overflow-y: auto;
  padding-right: 16px;
  position: absolute;
  width: 100%;
`;
