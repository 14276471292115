import {
  BoxWrapper,
  BoxHeader,
  BoxHeaderIconWrapper,
  BoxHeaderLabel,
  ArrowLabel,
} from "./CollapseBox.style";
import type { TCollapseBoxProps } from "./model";
import { useState } from "react";

const CollapseBox = (props: TCollapseBoxProps) => {
  const {
    action,
    alwaysOpen = false,
    children,
    className,
    hideBorderBottom,
    initialCollapse,
    title,
    TitleIcon,
  } = props;
  const [collapse, setCollapse] = useState<boolean>(initialCollapse ?? true);

  return (
    <BoxWrapper
      alwaysOpen={alwaysOpen}
      className={className}
      hideBorderBottom={hideBorderBottom}
    >
      <BoxHeader
        data-testid="collapse"
        onClick={() => !alwaysOpen && setCollapse(!collapse)}
      >
        <BoxHeaderIconWrapper>
          <TitleIcon />
        </BoxHeaderIconWrapper>
        <BoxHeaderLabel>{title}</BoxHeaderLabel>
        {action}
        {!alwaysOpen && <ArrowLabel>{collapse ? "▲" : "▼"} </ArrowLabel>}
      </BoxHeader>
      {collapse ? <></> : children}
    </BoxWrapper>
  );
};

export default CollapseBox;
