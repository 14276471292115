import { Outlet } from "react-router-dom";
import Navbar from "../../components/Navbar";
import { AlertWrapper, HomeWrapper, StyledAlert } from "./Home.style";
import { useContext } from "react";
import { observer } from "mobx-react-lite";
import { AlertContext } from "../../store/mobx/Alert";

interface Props {
  public?: boolean;
}

const Home = (props: Props) => {
  const { alert } = useContext(AlertContext);

  return (
    <HomeWrapper>
      <AlertWrapper>
        {alert && (
          <StyledAlert message={alert.msg} type={alert.type} showIcon />
        )}
      </AlertWrapper>
      <Navbar items={[]} public={props.public} />
      <Outlet />
    </HomeWrapper>
  );
};

export default observer(Home);
