import { Navigate, Route, Routes } from "react-router-dom";
import { PublicRoute, PrivateRoute } from "./routeWrappers";
import { Auth, Dashboard, Document, Home, Login } from "../pages";

const Router = () => {
  return (
    <Routes>
      <Route element={<PublicRoute />}>
        <Route element={<Home public />}>
          <Route path="login" element={<Login />} />
          <Route path="auth" element={<Auth />} />
        </Route>
      </Route>

      <Route element={<PrivateRoute />}>
        <Route element={<Home />}>
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="document" element={<Document />} />
          <Route path="*" element={<Navigate to={"dashboard"} replace />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default Router;
