import {
  Button,
  DatePicker,
  Input,
  InputNumber,
  Select,
  Skeleton,
  Tooltip,
  Typography,
} from "antd";
import { observer } from "mobx-react-lite";
import { useContext, useMemo } from "react";
import { BuildingHouse, Data } from "@styled-icons/boxicons-regular";

import { DashboardStoreContext } from "../../store/mobx/Dashboard";
import CollapseBox from "../CollapseBox";
import { StyledItemContainer, StyledListContainer } from "./Case.style";
import { Fields } from "../../types";
import { ClearOutlined, LinkOutlined } from "@ant-design/icons";
import { PersonLinesFill } from "@styled-icons/bootstrap";
import { dateFormat, getDefaultDateValue, linkedDocInfo } from "./helper";
import { FiltersOptionsContext } from "../../store/mobx/FiltersOptionsStore";
import { AlertContext } from "../../store/mobx/Alert";
import { filter, startCase } from "lodash";
import { containsDigits } from "utils/func";

const { TextArea } = Input;
const { Text } = Typography;
const fullWidthStyle = { width: "100%" };
const readonlyMsg = "This field is read-only and can't be edited.";

const Case = () => {
  const {
    isFieldLinkedToAnotherDocument,
    linkUnlinkDoc,
    requestingCases,
    requestingCase,
    selectedCase,
    selectedDocument,
    setValueToQA,
    shouldDisableField,
    shouldDisableDueCrawledOnlyField,
  } = useContext(DashboardStoreContext);
  const filtersOptionsStore = useContext(FiltersOptionsContext);
  const { setAlert } = useContext(AlertContext);

  const unlinkButton = useMemo(
    () => (field: keyof Fields) => {
      const shouldUnlink =
        selectedCase?.fields.qaFieldsDocumentIds[field] ===
        selectedDocument?.id;

      return (
        <Button
          disabled={shouldDisableDueCrawledOnlyField(field)}
          onClick={() => {
            if (shouldUnlink) {
              linkUnlinkDoc(field, "unlink");
              setAlert({
                msg: `${startCase(field)} unlinked`,
                type: "success",
              });
            } else {
              linkUnlinkDoc(field, "link");
              setAlert({
                msg: `${startCase(field)} linked to ${selectedDocument?.title}`,
                type: "success",
              });
            }
          }}
          type="primary"
        >
          {shouldUnlink ? <ClearOutlined /> : <LinkOutlined />}
        </Button>
      );
    },
    [
      linkUnlinkDoc,
      selectedCase?.fields.qaFieldsDocumentIds,
      selectedDocument?.id,
      selectedDocument?.title,
      setAlert,
      shouldDisableDueCrawledOnlyField,
    ]
  );

  if (requestingCases || requestingCase) {
    return <Skeleton active />;
  }

  if (!selectedCase) return null;

  return (
    <>
      <CollapseBox
        hideBorderBottom
        initialCollapse={false}
        title="Building"
        TitleIcon={BuildingHouse}
      >
        <StyledListContainer>
          <Text strong>Total Lot Area Sq Feet</Text>
          <StyledItemContainer>
            <InputNumber
              defaultValue={selectedCase.fields.crawled.totalAreaSqFeet}
              disabled={shouldDisableField("totalAreaSqFeet")}
              min={1}
              onChange={(value) => setValueToQA("totalAreaSqFeet", value)}
              style={{ width: "100%" }}
              value={selectedCase.fields.qa.totalAreaSqFeet}
            />
            {unlinkButton("totalAreaSqFeet")}
          </StyledItemContainer>
          {isFieldLinkedToAnotherDocument("totalAreaSqFeet") &&
            linkedDocInfo("totalAreaSqFeet", selectedCase)}

          <Text strong>Present Uses</Text>
          <StyledItemContainer>
            <Select
              allowClear
              defaultValue={selectedCase.fields.crawled.presentUses}
              disabled={shouldDisableField("presentUses")}
              mode="multiple"
              onChange={(value) => setValueToQA("presentUses", value)}
              options={filtersOptionsStore.presentUse}
              style={fullWidthStyle}
              value={selectedCase.fields.qa.presentUses}
            />
            {unlinkButton("presentUses")}
          </StyledItemContainer>
          {isFieldLinkedToAnotherDocument("presentUses") &&
            linkedDocInfo("presentUses", selectedCase)}

          <Text strong>Proposed Uses</Text>
          <StyledItemContainer>
            <Select
              allowClear
              defaultValue={selectedCase.fields.crawled.proposedUses}
              disabled={shouldDisableField("proposedUses")}
              mode="multiple"
              onChange={(value) => setValueToQA("proposedUses", value)}
              options={filtersOptionsStore.proposedUse}
              style={fullWidthStyle}
              value={selectedCase.fields.qa.proposedUses}
            />
            {unlinkButton("proposedUses")}
          </StyledItemContainer>
          {isFieldLinkedToAnotherDocument("proposedUses") &&
            linkedDocInfo("proposedUses", selectedCase)}

          <Text strong>New Construction Sq Feet</Text>
          <StyledItemContainer>
            <InputNumber
              defaultValue={selectedCase.fields.crawled.newConstructionSqFeet}
              disabled={shouldDisableField("newConstructionSqFeet")}
              min={1}
              onChange={(value) => setValueToQA("newConstructionSqFeet", value)}
              style={{ width: "100%" }}
              value={selectedCase.fields.qa.newConstructionSqFeet}
            />
            {unlinkButton("newConstructionSqFeet")}
          </StyledItemContainer>
          {isFieldLinkedToAnotherDocument("newConstructionSqFeet") &&
            linkedDocInfo("newConstructionSqFeet", selectedCase)}

          <Text strong># Units</Text>
          <StyledItemContainer>
            <InputNumber
              defaultValue={selectedCase.fields.crawled.nUnits}
              disabled={shouldDisableField("nUnits")}
              min={1}
              onChange={(value) => setValueToQA("nUnits", value)}
              style={{ width: "100%" }}
              value={selectedCase.fields.qa.nUnits}
            />
            {unlinkButton("nUnits")}
          </StyledItemContainer>
          {isFieldLinkedToAnotherDocument("nUnits") &&
            linkedDocInfo("nUnits", selectedCase)}
        </StyledListContainer>
      </CollapseBox>

      <CollapseBox
        hideBorderBottom
        initialCollapse={false}
        title="Case Data"
        TitleIcon={Data}
      >
        <StyledListContainer>
          <Text strong>Completion Date</Text>
          <Tooltip title={readonlyMsg}>
            <DatePicker
              defaultValue={getDefaultDateValue("completionDate", selectedCase)}
              disabled={shouldDisableField("completionDate")}
              format={dateFormat}
              onChange={(date, dateString) => console.log(date, dateString)}
              style={fullWidthStyle}
            />
          </Tooltip>

          <Text strong>Filing Date</Text>
          <Tooltip title={readonlyMsg}>
            <DatePicker
              defaultValue={getDefaultDateValue("filingDate", selectedCase)}
              disabled={shouldDisableField("filingDate")}
              format={dateFormat}
              onChange={(date, dateString) => console.log(date, dateString)}
              style={fullWidthStyle}
            />
          </Tooltip>

          <Text strong>Description</Text>
          <StyledItemContainer>
            <TextArea
              allowClear
              autoSize={{ minRows: 2 }}
              defaultValue={selectedCase.fields.crawled.description}
              disabled={shouldDisableField("description")}
              onChange={(e) => setValueToQA("description", e.target.value)}
              value={selectedCase.fields.qa.description}
            />
            {unlinkButton("description")}
          </StyledItemContainer>
          {isFieldLinkedToAnotherDocument("description") &&
            linkedDocInfo("description", selectedCase)}

          <Text strong>Prefix</Text>
          <Tooltip title={readonlyMsg}>
            <div>
              <TextArea
                allowClear
                autoSize
                defaultValue={selectedCase.fields.crawled.prefix}
                disabled={shouldDisableField("prefix")}
                onChange={(e) => setValueToQA("prefix", e.target.value)}
                value={selectedCase.fields.qa.prefix}
              />
            </div>
          </Tooltip>

          <Text strong>Suffixes</Text>
          <Tooltip title={readonlyMsg}>
            <Select
              allowClear
              defaultValue={selectedCase.fields.crawled.suffixes}
              disabled={shouldDisableField("suffixes")}
              mode="tags"
              onChange={(value) => setValueToQA("suffixes", value)}
              style={fullWidthStyle}
              value={selectedCase.fields.qa.suffixes}
            />
          </Tooltip>
        </StyledListContainer>
      </CollapseBox>

      <CollapseBox
        hideBorderBottom
        initialCollapse={false}
        title="Entities Involved"
        TitleIcon={PersonLinesFill}
      >
        <StyledListContainer>
          <Text strong>Applicant Companies</Text>
          <StyledItemContainer>
            <Select
              allowClear
              defaultValue={selectedCase.fields.crawled.applicantCompanies}
              disabled={shouldDisableField("applicantCompanies")}
              mode="tags"
              onChange={(value) =>
                setValueToQA(
                  "applicantCompanies",
                  filter(value, (str) => !containsDigits(str))
                )
              }
              style={fullWidthStyle}
              value={selectedCase.fields.qa.applicantCompanies}
            />
            {unlinkButton("applicantCompanies")}
          </StyledItemContainer>
          {isFieldLinkedToAnotherDocument("applicantCompanies") &&
            linkedDocInfo("applicantCompanies", selectedCase)}

          <Text strong>Applicant Names</Text>
          <StyledItemContainer>
            <Select
              allowClear
              defaultValue={selectedCase.fields.crawled.applicantNames}
              disabled={shouldDisableField("applicantNames")}
              mode="tags"
              onChange={(value) =>
                setValueToQA(
                  "applicantNames",
                  filter(value, (str) => !containsDigits(str))
                )
              }
              style={fullWidthStyle}
              value={selectedCase.fields.qa.applicantNames}
            />
            {unlinkButton("applicantNames")}
          </StyledItemContainer>
          {isFieldLinkedToAnotherDocument("applicantNames") &&
            linkedDocInfo("applicantNames", selectedCase)}

          <Text strong>Representative Companies</Text>
          <StyledItemContainer>
            <Select
              allowClear
              defaultValue={selectedCase.fields.crawled.representativeCompanies}
              disabled={shouldDisableField("representativeCompanies")}
              mode="tags"
              onChange={(value) =>
                setValueToQA(
                  "representativeCompanies",
                  filter(value, (str) => !containsDigits(str))
                )
              }
              style={fullWidthStyle}
              value={selectedCase.fields.qa.representativeCompanies}
            />
            {unlinkButton("representativeCompanies")}
          </StyledItemContainer>
          {isFieldLinkedToAnotherDocument("representativeCompanies") &&
            linkedDocInfo("representativeCompanies", selectedCase)}

          <Text strong>Representative Names</Text>
          <StyledItemContainer>
            <Select
              allowClear
              defaultValue={selectedCase.fields.crawled.representativeNames}
              disabled={shouldDisableField("representativeNames")}
              mode="tags"
              onChange={(value) =>
                setValueToQA(
                  "representativeNames",
                  filter(value, (str) => !containsDigits(str))
                )
              }
              style={fullWidthStyle}
              value={selectedCase.fields.qa.representativeNames}
            />
            {unlinkButton("representativeNames")}
          </StyledItemContainer>
          {isFieldLinkedToAnotherDocument("representativeNames") &&
            linkedDocInfo("representativeNames", selectedCase)}
        </StyledListContainer>
      </CollapseBox>
    </>
  );
};

export default observer(Case);
