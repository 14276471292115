import { Layout, ConfigProvider, Skeleton } from "antd";
import { useEffect, useState } from "react";
import Documents from "components/Documents";
import { StyledLayout } from "./Dashboard.style";
import DashboardStore, { DashboardStoreContext } from "store/mobx/Dashboard";
import FiltersOptionsStore, {
  FiltersOptionsContext,
} from "store/mobx/FiltersOptionsStore";
import { assign } from "actions/cases";

const Dashboard = () => {
  const [loading, setloading] = useState(true);

  useEffect(() => {
    const assignCases = async () => {
      await assign();
      setloading(false);
    };

    assignCases();
  }, []);

  if (loading) {
    return <Skeleton active style={{ margin: "0 25px", width: "auto" }} />;
  }

  return (
    <Layout>
      <DashboardStoreContext.Provider value={DashboardStore}>
        <FiltersOptionsContext.Provider value={new FiltersOptionsStore()}>
          <StyledLayout>
            <ConfigProvider
              getPopupContainer={(node) => {
                if (node) {
                  return node.parentNode as HTMLElement;
                }
                return document.body;
              }}
            >
              <Documents />
            </ConfigProvider>
          </StyledLayout>
        </FiltersOptionsContext.Provider>
      </DashboardStoreContext.Provider>
    </Layout>
  );
};

export default Dashboard;
