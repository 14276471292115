import { Row } from "antd";
import styled from "styled-components";

export const StyledContainer = styled(Row)`
  padding-bottom: 16px;
`;

export const StyledRowContainer = styled.div`
  width: 100%;
`;

export const StyledDocumentsNavigator = styled.div`
  display: flex;
  column-gap: 16px;
  padding-left: 24px;
  padding-top: 16px;
`;

export const StyledSelectCases = styled.div`
  display: flex;
  gap: 10px;
  padding-right: 16px;
  padding-top: 16px;
`;
