import dayjs from "dayjs";
import { Typography } from "antd";
import { Fields, SelectedCase } from "../../types";

const { Text } = Typography;

export const dateFormat = "MM/DD/YYYY";

export const getDefaultDateValue = (
  field: "completionDate" | "filingDate",
  selectedCase: SelectedCase
) => {
  const { qa, crawled } = selectedCase.fields;
  const date = qa[field] || crawled[field];
  if (date) {
    return dayjs(new Date(date));
  }
  return undefined;
};

export const linkedDocInfo = (
  field: keyof Fields,
  selectedCase: SelectedCase
) => {
  const linkedDocId = selectedCase.fields.qaFieldsDocumentIds[field];
  if (linkedDocId) {
    const linkedDocName = selectedCase.documents.find(
      (doc) => doc.id === linkedDocId
    )?.title;
    return (
      <Text
        type="danger"
        style={{ cursor: "default", fontSize: "12px", gridColumn: "1 / -1" }}
      >
        {`This field is linked to ${linkedDocName}`}
      </Text>
    );
  }
  return "";
};
