import { Spin } from "antd";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../store/mobx/AuthStore";
import { StyledContainer } from "./Auth.styles";

const Auth = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useContext(AuthContext);

  if (isAuthenticated) {
    navigate("/dashboard");
  }

  return (
    <StyledContainer>
      <Spin size="large" tip="Authenticating..." />
    </StyledContainer>
  );
};

export default Auth;
