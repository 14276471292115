import { BrowserRouter } from "react-router-dom";
import { ConfigProvider } from "antd";
import AlertProvider from "./components/AlertProvider";
import AuthProvider from "./components/AuthProvider";
import Router from "./router";

const theme = {
  token: {
    colorPrimary: "#DE5300",
    fontFamily: "Manrope",
    colorTextSecondary: "#0C3BB9",
  },
};

const App = () => {
  return (
    <AuthProvider>
      <BrowserRouter>
        <ConfigProvider theme={theme}>
          <AlertProvider>
            <Router />
          </AlertProvider>
        </ConfigProvider>
      </BrowserRouter>
    </AuthProvider>
  );
};

export default App;
