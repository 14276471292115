interface DocumentLinkProps {
  children: JSX.Element | string | number;
  docUrl: string | undefined;
}
export const DocumentLink = ({ children, docUrl }: DocumentLinkProps) => {
  if (!docUrl) return <></>;

  return (
    <a href={`/document?url=${docUrl}`} target="_blank" rel="noreferrer">
      {children}
    </a>
  );
};
