import { useSearchParams } from "react-router-dom";
import { StyledLayout } from "./styles";
import DocumentViewer from "../../components/DocumentViewer";

const Document = () => {
  const [searchParams] = useSearchParams();
  const url = searchParams.get("url");

  return <StyledLayout>{url && <DocumentViewer s3Url={url} />}</StyledLayout>;
};

export default Document;
