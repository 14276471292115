import { makeAutoObservable } from "mobx";
import { createContext } from "react";
import axios from "axios";
import { ATC_TOKEN_KEY, ATC_USER_KEY } from "../../../utils/constants";
import { me } from "../../../actions/me";

class AuthStore {
  token = localStorage.getItem(ATC_TOKEN_KEY);

  constructor() {
    makeAutoObservable(this);

    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.status === 401) {
          this.signOut();
        }
        return Promise.reject(error);
      }
    );
  }

  get isAuthenticated() {
    return !!this.token;
  }

  getUser = () => {
    const user = localStorage.getItem(ATC_USER_KEY);
    if (typeof user === "string") {
      return JSON.parse(user);
    }
  };

  setUser = (user: string | null) => {
    user
      ? localStorage.setItem(ATC_USER_KEY, user)
      : localStorage.removeItem(ATC_USER_KEY);
  };

  getToken = () => {
    const token = localStorage.getItem(ATC_TOKEN_KEY);
    if (typeof token === "string") {
      return JSON.parse(token);
    }
  };

  setToken = (token: string | null) => {
    this.token = token;
    token
      ? localStorage.setItem(ATC_TOKEN_KEY, token)
      : localStorage.removeItem(ATC_TOKEN_KEY);
  };

  signIn = async (email: string, password: string) => {
    try {
      const credentials = btoa(`${email}:${password}`);
      const meInfo = await me(credentials);
      this.setToken(credentials);
      this.setUser(JSON.stringify(meInfo));
    } catch (error) {
      this.signOut();
      throw error;
    }
  };

  signOut = () => {
    this.setToken(null);
    this.setUser(null);
  };
}

export const AuthContext = createContext<AuthStore>({} as AuthStore);

export default AuthStore;
