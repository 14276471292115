import { map } from "lodash";
import API_CALL from "../api";

const formatValues = (values: string[]) => {
  return map(values, (value) => ({ value, label: value }));
};

type QueryParams = {
  contains: string;
  limit?: number;
  city?: string;
};

const TYPEAHEAD_CACHE: Record<string, any> = {};

export const makeTypeaheadReq = async (
  path: string,
  queryParams: QueryParams
) => {
  const cacheKey = `${path}${JSON.stringify(queryParams)}`;

  if (!TYPEAHEAD_CACHE[cacheKey]) {
    const response = await API_CALL({
      method: "GET",
      path,
      queryParams,
    });
    TYPEAHEAD_CACHE[cacheKey] = response.data;
  }

  return formatValues(TYPEAHEAD_CACHE[cacheKey]);
};
