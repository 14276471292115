import type { AxiosRequestHeaders } from "axios";
import axios, { type AxiosRequestConfig } from "axios";
import { ATC_TOKEN_KEY } from "../../utils/constants";

interface Props {
  body?: any;
  headers?: AxiosRequestHeaders;
  method: "GET" | "POST" | "PUT" | "DELETE";
  path: string;
  queryParams?: Record<string, any>;
}

const API_CALL = (requestParams: Props) => {
  const {
    method,
    path,
    body = {},
    headers = {},
    queryParams = {},
  } = requestParams;

  // NOTE: we need the BASE_URL when deploying to dev/prod.
  const BASE_URL = process.env.REACT_APP_BACKEND_URL;

  const config: AxiosRequestConfig = {
    url: `${BASE_URL}/${path}`,
    method: method,
    headers: {
      Authorization: `Basic ${localStorage.getItem(ATC_TOKEN_KEY)}`,
      ...headers,
      responseType: "json",
    },
    data: body,
    params: queryParams,
  };

  return axios(config);
};

export default API_CALL;
