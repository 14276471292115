import { Form, Input, Result } from "antd";
import { useContext, useState } from "react";
import { AuthContext } from "../../store/mobx/AuthStore";
import {
  StyledButton,
  StyledContainer,
  StyledDescription,
  StyledForm,
  StyledFormContainer,
  StyledLayout,
  StyledOrange,
} from "./Login.style";
import { logoAndText } from "../../assets/images/index";
import { useNavigate } from "react-router-dom";

enum AuthState {
  IDLE,
  AUTHENTICATING,
  AUTHENTICATED,
}

enum ResultState {
  IDLE,
  SUCCESS,
  FAILURE,
}

const Login = () => {
  const navigate = useNavigate();
  const { signIn } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [resultState, setResultState] = useState(ResultState.IDLE);
  const [authState, setAuthState] = useState(AuthState.IDLE);

  const handleClick = async () => {
    setAuthState(AuthState.AUTHENTICATING);
    setResultState(ResultState.IDLE);

    if (email && password) {
      try {
        await signIn(email, password);
        navigate("/dashboard");
      } catch (error) {
        setAuthState(AuthState.IDLE);
        setResultState(ResultState.FAILURE);
      }
    }
  };

  const isAuthenticating = authState === AuthState.AUTHENTICATING;

  return (
    <StyledContainer>
      <StyledLayout>
        <div>
          <img src={logoAndText} alt="Logo" />
          <StyledDescription>
            Bringing <StyledOrange>transparency</StyledOrange>
            <br /> and <StyledOrange>efficiency</StyledOrange> to the
            <br /> land entitlement process
          </StyledDescription>
        </div>
        <StyledFormContainer>
          <h1>Sign in</h1>
          <StyledForm>
            <Form.Item
              name="email"
              rules={[{ required: true, message: "Please input your email" }]}
            >
              <Input
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                size="large"
                value={email}
              />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                { required: true, message: "Please input your password" },
              ]}
            >
              <Input
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                size="large"
                type="password"
                value={password}
              />
            </Form.Item>

            <Form.Item>
              <StyledButton
                disabled={
                  !email ||
                  !password ||
                  isAuthenticating ||
                  authState === AuthState.AUTHENTICATED
                }
                loading={isAuthenticating}
                onClick={handleClick}
                size="large"
                type="primary"
              >
                Login
              </StyledButton>
            </Form.Item>
          </StyledForm>

          {resultState === ResultState.FAILURE && (
            <Result
              status="error"
              title="Please check your email and try again."
            />
          )}
        </StyledFormContainer>
      </StyledLayout>
    </StyledContainer>
  );
};

export default Login;
