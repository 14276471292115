import { Alert } from "antd";
import styled from "styled-components";

export const HomeWrapper = styled.div`
  width: inherit;
  height: inherit;
  display: flex;
  flex-direction: column;
`;

export const AlertWrapper = styled.div`
  position: absolute;
  top: 4px;
  width: 100%;
`;

export const StyledAlert = styled(Alert)`
  margin: 0 auto;
  width: 50%;
`;
