import { sortBy } from "lodash";
import { ATC_USER_KEY, ENDPOINTS } from "../../utils/constants";
import API_CALL from "../api";
import dashboardStore from "../../store/mobx/Dashboard";

const getUserQueryParam = () => {
  const user = JSON.parse(localStorage.getItem(ATC_USER_KEY) || "{}");
  return user.id ? { user_id: user.id } : {};
};

export const getCases = async () => {
  const { setCases, setRequesting } = dashboardStore;
  setRequesting("cases", true);

  try {
    const { data = [] } = await API_CALL({
      method: "GET",
      path: ENDPOINTS.cases,
      queryParams: getUserQueryParam(),
    });

    setCases(sortBy(data, ["caseNumber"]));
    setRequesting("cases", false);
  } catch (e) {
    console.error(e);
  } finally {
    setRequesting("cases", false);
  }
};

export const assign = async () => {
  try {
    const response = await API_CALL({
      method: "POST",
      path: `${ENDPOINTS.cases}/assign`,
      queryParams: getUserQueryParam(),
    });

    return response;
  } catch (error) {
    throw error;
  }
};
