/* eslint-disable jsx-a11y/anchor-has-content */
import { useContext } from "react";
import { Button, Skeleton } from "antd";
import { observer } from "mobx-react-lite";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import type {
  ToolbarProps,
  ToolbarSlot,
  TransformToolbarSlot,
} from "@react-pdf-viewer/toolbar";
import { StyledContainer } from "./DocumentViewer.style";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { DOCS_PATH } from "../../utils/constants";
import DropboxSaver from "../Dropbox";
import { DashboardStoreContext } from "../../store/mobx/Dashboard";
import { ExportOutlined } from "@ant-design/icons";
import { DocumentLink } from "utils/tools";

interface Props {
  s3Url: string;
}

const DocumentViewer = ({ s3Url }: Props) => {
  const { requestingCases, requestingCase } = useContext(DashboardStoreContext);
  const documentURL = `${DOCS_PATH}${s3Url}`;
  const isDocumentPage = window.location.pathname.includes("/document");

  const transform: TransformToolbarSlot = (slot: ToolbarSlot) => ({
    ...slot,
    Open: () => <></>,
    EnterFullScreen: () => <></>,
    SwitchTheme: () => <></>,
    // NOTE: Highjack "Print" slot and use it to the dropbox saver button.
    Print: () => (
      <div>
        <DropboxSaver url={documentURL} name={`${s3Url}.pdf`} />
        {!isDocumentPage && (
          <DocumentLink docUrl={s3Url}>
            <Button
              type="link"
              icon={<ExportOutlined />}
              size="small"
              style={{ marginLeft: "5px", marginTop: "5px" }}
            />
          </DocumentLink>
        )}
      </div>
    ),
  });

  const renderToolbar = (
    Toolbar: (props: ToolbarProps) => React.ReactElement
  ) => <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>;

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: () => [],
    renderToolbar,
  });

  const { renderDefaultToolbar } =
    defaultLayoutPluginInstance.toolbarPluginInstance;

  if (requestingCases || requestingCase) {
    return <Skeleton active />;
  }

  if (!s3Url) return null;

  return (
    <>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.15.349/build/pdf.worker.min.js">
        <StyledContainer>
          <Viewer
            fileUrl={documentURL}
            plugins={[defaultLayoutPluginInstance]}
            theme="auto"
          />
        </StyledContainer>
      </Worker>
    </>
  );
};

export default observer(DocumentViewer);
