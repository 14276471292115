import { isEqual } from "lodash";
import { createContext } from "react";
import { makeAutoObservable } from "mobx";
import { Case, Document, Fields, SelectedCase } from "../../../types";
import {
  completeReviewReq,
  getCase,
  tempSaveCase,
} from "../../../actions/case";
import { assign, getCases } from "../../../actions/cases";

class DashboardStore {
  cases: Case[] = [];
  // Save the original case so we can compare it to the updated one when selecting a new one.
  originalSelectedCase: SelectedCase | undefined;
  requestingCase = true;
  requestingCases = true;
  selectedCase: SelectedCase | undefined;
  selectedDocument: Document | undefined;

  constructor() {
    makeAutoObservable(this);
  }

  completeReview = async () => {
    try {
      if (this.selectedCase) {
        if (!isEqual(this.originalSelectedCase, this.selectedCase)) {
          await this.saveCase();
        }

        this.setRequesting("cases", true);
        await completeReviewReq(this.selectedCase);
        await assign();
        await getCases();
      }
    } catch (error) {
      // Note: We should handle this error on the UI.
      console.error(error);
    }
  };

  saveCase = async () => {
    const result = await tempSaveCase(
      this.selectedCase?.caseId,
      this.selectedCase
    );
    if (result && this.selectedCase) {
      this.selectedCase.version = result.version;
      this.originalSelectedCase = { ...this.selectedCase };
    }
  };

  setCases = (cases: Case[] = []) => {
    this.cases = cases;
    this.setSelectedCase(cases[0]?.caseId);
  };

  setRequesting = (type: "cases" | "case", value: boolean) => {
    if (type === "case") this.requestingCase = value;
    if (type === "cases") this.requestingCases = value;
  };

  setSelectedCase = async (caseId?: string) => {
    if (caseId) {
      const c = await getCase(caseId);
      this.selectedCase = c;
      this.originalSelectedCase = { ...c };
      this.setSelectedDocument(c.documents[0].id);
    }
  };

  linkUnlinkDoc = (field: keyof Fields, action: "link" | "unlink") => {
    if (this.selectedCase) {
      this.selectedCase.fields.qaFieldsDocumentIds[field] =
        action === "unlink" ? undefined : this.selectedDocument?.id;
    }
  };

  shouldDisableDueCrawledOnlyField = (field: keyof Fields) => {
    if (this.selectedCase) {
      const { crawled, qa } = this.selectedCase.fields;
      const onlyCrawled = field in crawled === true && field in qa === false;
      return onlyCrawled;
    }
    return false;
  };

  /* Should disable a field if it's crawled only,
   * or we have a value attached to another document */
  shouldDisableField = (field: keyof Fields) => {
    if (this.selectedCase) {
      const { qaFieldsDocumentIds } = this.selectedCase.fields;
      const savedInAnotherDoc =
        typeof qaFieldsDocumentIds[field] === "string" &&
        qaFieldsDocumentIds[field] !== this.selectedDocument?.id;
      return this.shouldDisableDueCrawledOnlyField(field) || savedInAnotherDoc;
    }
    return false;
  };

  setSelectedDocument = (id: string) => {
    this.selectedDocument = this.selectedCase?.documents.find(
      (doc) => doc.id === id
    );
  };

  setValueToQA = (
    field: keyof Fields,
    value?: number | string | string[] | null
  ) => {
    if (this.selectedCase) {
      this.selectedCase.fields.qa[field as string] = value;
      this.selectedCase.fields.qaFieldsDocumentIds[field] =
        this.selectedDocument?.id;
    }
  };

  get isFirstDocument() {
    return (
      this.selectedCase?.documents[0].id === this.selectedDocument?.id ||
      this.selectedCase?.documents.length === 1
    );
  }

  get isLastDocument() {
    return (
      this.selectedCase?.documents[this.selectedCase?.documents.length - 1]
        .id === this.selectedDocument?.id ||
      this.selectedCase?.documents.length === 1
    );
  }

  navigateToDocument = (direction: "next" | "previous") => {
    if (this.selectedCase) {
      const index = this.selectedCase.documents.findIndex(
        (doc) => doc.id === this.selectedDocument?.id
      );
      if (index !== -1) {
        if (direction === "next") {
          if (index < this.selectedCase.documents.length - 1) {
            this.setSelectedDocument(this.selectedCase.documents[index + 1].id);
          }
        } else {
          if (index > 0) {
            this.setSelectedDocument(this.selectedCase.documents[index - 1].id);
          }
        }
      }
    }
  };

  isFieldLinkedToAnotherDocument = (field: keyof Fields) => {
    if (this.selectedCase) {
      const linkedDocId = this.selectedCase.fields.qaFieldsDocumentIds[field];
      return linkedDocId && linkedDocId !== this.selectedDocument?.id;
    }
    return false;
  };
}

export const DashboardStoreContext = createContext<DashboardStore>(
  {} as DashboardStore
);

export default new DashboardStore();
