import { ENDPOINTS } from "../../utils/constants";
import API_CALL from "../api";

export const me = async (credentials: string) => {
  try {
    const { data: token } = await API_CALL({
      method: "GET",
      path: ENDPOINTS.me,
      headers: { Authorization: `Basic ${credentials}` },
    });

    return token;
  } catch (error) {
    throw error;
  }
};
