import { makeAutoObservable } from "mobx";
import { createContext } from "react";

interface Alert {
  msg: string;
  type: "success" | "error" | "info" | "warning";
}

class AlertStore {
  alert?: Alert = undefined;
  timeoutId?: NodeJS.Timeout = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  setAlert = (props: Alert, shouldTimeout: boolean = true) => {
    clearTimeout(this.timeoutId);
    this.alert = props;

    if (shouldTimeout) {
      this.timeoutId = setTimeout(() => {
        this.alert = undefined;
      }, 5000);
    }
  };
}

export const AlertContext = createContext<AlertStore>({} as AlertStore);

export default AlertStore;
