import { debounce } from "lodash";
import { createContext } from "react";
import { makeAutoObservable } from "mobx";
import type { SelectFormat } from "./model";
import { APPEALED_BY_DATA, CITY_DATA, USES_DATA } from "./data";
import { makeTypeaheadReq } from "../../../actions/filters";
import { ENDPOINTS } from "../../../utils/constants";

// This class is responsible for storing the options for the filters.
// Since we're still not using filters in the app, this class is being
// used to store the options for Proposed/Present Uses typeahead fields only.
// Its use might be expanded in the future.
// That's why I decided to keep all the other methods.
class FiltersOptionsStore {
  areaPlanningCommission?: SelectFormat[] = undefined;
  appealedBy: SelectFormat[] = APPEALED_BY_DATA;
  applicantNames?: SelectFormat[] = undefined;
  applicantCompanies?: SelectFormat[] = undefined;
  baseCase?: SelectFormat[] = undefined;
  city = CITY_DATA;
  communityPlanningAreas?: SelectFormat[] = undefined;
  determinations?: SelectFormat[] = undefined;
  parcelNumbers?: SelectFormat[] = undefined;
  parcelZoning?: SelectFormat[] = undefined;
  prefixes?: SelectFormat[] = undefined;
  representativeNames?: SelectFormat[] = undefined;
  representativeCompanies?: SelectFormat[] = undefined;
  specificPlanAreas?: SelectFormat[] = undefined;
  suffixes?: SelectFormat[] = undefined;
  staffAssigned?: SelectFormat[] = undefined;
  presentUse: SelectFormat[] = USES_DATA;
  proposedUse: SelectFormat[] = USES_DATA;

  constructor() {
    makeAutoObservable(this);
    // this.fetchAllParcelZoning();
  }

  searchApplicantNames = debounce(async (contains: string = "") => {
    this.setApplicantNames(undefined);
    const results = await makeTypeaheadReq(ENDPOINTS.applicantNames, {
      contains,
    });
    this.setApplicantNames(results);
  }, 300);

  setApplicantNames = (value: SelectFormat[] | undefined) => {
    this.applicantNames = value;
  };

  searchApplicantCompanies = debounce(async (contains: string = "") => {
    this.setApplicantCompanies(undefined);
    const results = await makeTypeaheadReq(ENDPOINTS.applicantCompanies, {
      contains,
    });
    this.setApplicantCompanies(results);
  }, 300);

  setApplicantCompanies = (value: SelectFormat[] | undefined) => {
    this.applicantCompanies = value;
  };

  searchAPC = debounce(async (contains: string = "") => {
    this.setAPC(undefined);
    const results = await makeTypeaheadReq(ENDPOINTS.arePlanningCommission, {
      contains,
    });
    this.setAPC(results);
  }, 300);

  setAPC = (value: SelectFormat[] | undefined) => {
    this.areaPlanningCommission = value;
  };

  searchBaseCases = debounce(async (contains: string = "") => {
    this.setBaseCase(undefined);
    const results = await makeTypeaheadReq(ENDPOINTS.casesTypeahead, {
      contains,
    });
    this.setBaseCase(results);
  }, 300);

  setBaseCase = (value: SelectFormat[] | undefined) => {
    this.baseCase = value;
  };

  setPrefixes = (value: SelectFormat[] | undefined) => {
    this.prefixes = value;
  };

  setSuffixes = (value: SelectFormat[] | undefined) => {
    this.suffixes = value;
  };

  searchCPA = debounce(async (contains: string = "") => {
    this.setCPA(undefined);
    const results = await makeTypeaheadReq(ENDPOINTS.communityPlanningArea, {
      contains,
    });
    this.setCPA(results);
  }, 300);

  setCPA = (value: SelectFormat[] | undefined) => {
    this.communityPlanningAreas = value;
  };

  searchParcelNumbers = debounce(async (contains: string = "") => {
    this.setParcelNumbers(undefined);
    const results = await makeTypeaheadReq(ENDPOINTS.parcelNumber, {
      contains,
    });
    this.setParcelNumbers(results);
  }, 300);

  setParcelNumbers = (value: SelectFormat[] | undefined) => {
    this.parcelNumbers = value;
  };

  // This used to be a regular typeahead function like the other.
  // Natanael asked to load all zoning data upfront, and make it
  // work like Prefix/Suffix filters with all the data available.
  fetchAllParcelZoning = async () => {
    const results = await makeTypeaheadReq(ENDPOINTS.parcelZoning, {
      contains: "",
      limit: 10000,
    });
    this.setParcelZoning(results);
  };

  setParcelZoning = (value: SelectFormat[] | undefined) => {
    this.parcelZoning = value;
  };

  searchRepresentativeNames = debounce(async (contains: string = "") => {
    this.setRepresentativeNames(undefined);
    const results = await makeTypeaheadReq(ENDPOINTS.representativeNames, {
      contains,
    });
    this.setRepresentativeNames(results);
  }, 300);

  setRepresentativeNames = (value: SelectFormat[] | undefined) => {
    this.representativeNames = value;
  };

  searchRepresentativeCompanies = debounce(async (contains: string = "") => {
    this.setRepresentativeCompanies(undefined);
    const results = await makeTypeaheadReq(ENDPOINTS.representativeCompanies, {
      contains,
    });
    this.setRepresentativeCompanies(results);
  }, 300);

  setRepresentativeCompanies = (value: SelectFormat[] | undefined) => {
    this.representativeCompanies = value;
  };

  searchSpecificPlanAreas = debounce(async (contains: string = "") => {
    this.setSpecificPlanAreas(undefined);
    const results = await makeTypeaheadReq(ENDPOINTS.specificPlanAreas, {
      contains,
    });
    this.setSpecificPlanAreas(results);
  }, 300);

  setSpecificPlanAreas = (value: SelectFormat[] | undefined) => {
    this.specificPlanAreas = value;
  };

  searchStaffAssigned = debounce(async (contains: string = "") => {
    this.setStaffAssigned(undefined);
    const results = await makeTypeaheadReq(ENDPOINTS.staffAssigned, {
      contains,
    });
    this.setStaffAssigned(results);
  }, 300);

  setStaffAssigned = (value: SelectFormat[] | undefined) => {
    this.staffAssigned = value;
  };

  searchDeterminations = debounce(async (contains: string = "") => {
    this.setDeterminations(undefined);
    const results = await makeTypeaheadReq(ENDPOINTS.determination, {
      contains,
    });
    this.setDeterminations(results);
  }, 300);

  setDeterminations = (value: SelectFormat[] | undefined) => {
    this.determinations = value;
  };
}

export const FiltersOptionsContext = createContext<FiltersOptionsStore>(
  {} as FiltersOptionsStore
);

export default FiltersOptionsStore;
