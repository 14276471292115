import { Col, Button, Select } from "antd";
import { isEqual, map } from "lodash";
import { useContext } from "react";

import {
  StyledContainer,
  StyledSelectCases,
  StyledDocumentsNavigator,
} from "./DocumentsToolbar.styles";
import { DashboardStoreContext } from "../../../store/mobx/Dashboard";
import { observer } from "mobx-react-lite";

const DocumentsToolbar = () => {
  const {
    cases,
    completeReview,
    isFirstDocument,
    isLastDocument,
    navigateToDocument,
    originalSelectedCase,
    requestingCase,
    saveCase,
    selectedCase,
    selectedDocument,
    setSelectedCase,
    setSelectedDocument,
  } = useContext(DashboardStoreContext);

  return (
    <StyledContainer>
      <Col span={11}>
        <StyledSelectCases>
          <Select
            disabled={requestingCase}
            options={cases.map((item) => ({
              value: item.caseId,
              label: item.caseNumber,
            }))}
            placeholder="Select a case"
            style={{ width: "100%" }}
            value={selectedCase?.caseId}
            onChange={(value) => {
              const equal = isEqual(originalSelectedCase, selectedCase);
              const confirmMsg =
                "Are you sure you want to leave this case? All unsaved changes will be lost.";

              if (equal || window.confirm(confirmMsg)) {
                return setSelectedCase(value);
              }
            }}
          />
          <Button onClick={saveCase} type="primary">
            Save
          </Button>
          <Button onClick={completeReview} type="primary">
            Complete Review
          </Button>
        </StyledSelectCases>
      </Col>

      <Col span={13}>
        <StyledDocumentsNavigator>
          <Button
            disabled={isFirstDocument}
            onClick={() => navigateToDocument("previous")}
            type="primary"
          >
            Previous
          </Button>
          <Select
            onChange={setSelectedDocument}
            options={map(selectedCase?.documents, (document) => ({
              value: document.id,
              label: document.title,
            }))}
            style={{ width: 300 }}
            value={selectedDocument?.id}
          />
          <Button
            disabled={isLastDocument}
            onClick={() => navigateToDocument("next")}
            type="primary"
          >
            Next
          </Button>
        </StyledDocumentsNavigator>
      </Col>
    </StyledContainer>
  );
};

export default observer(DocumentsToolbar);
