import { Divider } from "antd";
import styled from "styled-components";

export const StyledDivider = styled(Divider)`
  grid-column: 1 / -1;
  margin: 0;
`;

export const StyledListContainer = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 8px;
`;

export const StyledItemContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;
