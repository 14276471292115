import { useContext } from "react";
import { Dropdown, type MenuProps } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import {
  NavbarWrapper,
  NavItem,
  NavItemLabel,
  NavIcon,
  NavIconWrapper,
  StyledLogo,
} from "./Navbar.style";
import { useNavigate } from "react-router-dom";
import { personIcon } from "../../assets/images";
import { AuthContext } from "../../store/mobx/AuthStore";
import { logoAndTextWhite } from "../../assets/images/index";

const menu = (signOut: () => void): MenuProps["items"] => [
  {
    icon: <LogoutOutlined />,
    key: "1",
    label: "Sign out",
    onClick: () => {
      signOut();
    },
  },
];

interface Props {
  items: Array<{
    id: string;
    label: string;
    path?: string;
    style?: any;
    onClick?: any;
  }>;
  public?: boolean;
}

const Navbar = (props: Props) => {
  const { items } = props;
  const navigate = useNavigate();
  const { signOut } = useContext(AuthContext);

  return (
    <NavbarWrapper>
      <StyledLogo
        alt="Logo"
        onClick={() => navigate("/")}
        src={logoAndTextWhite}
      />
      {items?.length > 0 &&
        items.map((item) => (
          <NavItem
            key={item.id}
            onClick={() =>
              item.onClick ? item.onClick() : navigate(`/${item.path}`)
            }
            style={item.style}
          >
            <NavItemLabel>{item.label}</NavItemLabel>
          </NavItem>
        ))}
      <Dropdown menu={{ items: menu(signOut) }}>
        <NavItem public={props.public}>
          {!props.public && (
            <NavIconWrapper>
              <NavIcon src={personIcon} />
            </NavIconWrapper>
          )}
        </NavItem>
      </Dropdown>
    </NavbarWrapper>
  );
};

export default Navbar;
