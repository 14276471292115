export enum ENDPOINTS {
  applicantNames = "cases/typeahead/applicantName",
  applicantCompanies = "cases/typeahead/applicantCompany",
  arePlanningCommission = "cases/typeahead/areaPlanningCommission",
  casesTypeahead = "cases/typeahead/baseCaseNumber",
  communityPlanningArea = "cases/typeahead/communityPlanningArea",
  determination = "cases/typeahead/determination",
  documentsSearch = "documents/search",
  parcelNumber = "cases/typeahead/parcelNumber",
  parcelZoning = "cases/typeahead/parcelZoning",
  prefixTypeahead = "cases/typeahead/prefix",
  project = "projects",
  projectsSearch = "projects/search",
  representativeNames = "cases/typeahead/representativeName",
  representativeCompanies = "cases/typeahead/representativeCompany",
  specificPlanAreas = "cases/typeahead/parcelSpecificPlanAreas",
  specificPlanAreasOverlays = "overlays/search",
  suffixTypeahead = "cases/typeahead/suffix",
  staffAssigned = "cases/typeahead/staffAssigned",
  cases = "qa/cases",
  me = "users/me",
}

export const ATC_TOKEN_KEY = "ATC_QA_token";
export const ATC_USER_KEY = "ATC_QA_user";
export const DOCS_PATH = "/files/";
