export const removeAcronym = (text: string) =>
  text[0] === "(" ? text.split(" ").splice(1).join(" ") : text;

export const ALL_OPTION = { value: "all", label: "-- Select all --" };

export const APPEALED_BY_DATA = [
  "Appeal By Applicant/Owner",
  "Person Other Than Applicant/Owner",
  "Appeal From a Determination made by LADBS",
].map((value) => ({ value, label: value }));

export const SANTA_MONICA_PREFIX_DATA = [
  "Entitlement",
  "Administrative Application",
  "Administrative Extension",
  "Zoning Compliance Letter",
  "Business License - Home Occupation",
  "Planning - Historic",
  "Planning - Pre-Submittal",
  // We need the values as is so the typeahead can work properly.
  // Before sending it to the API we remove the acronym when necessary.
].map((value) => ({ value: value.toUpperCase(), label: value }));

export const LA_PREFIX_DATA = [
  "(ADM) Administrative Review",
  "(AA) Advisory Agency",
  "(APCC) APC Central",
  "(APCE) APC East Los Angeles",
  "(APCH) APC Harbor",
  "(APCNV) APC North Valley",
  "(APCS) APC South Los Angeles",
  "(APCSV) APC South Valley",
  "(APCW) APC West Los Angeles",
  "(CPC) City Planning Commission",
  "(CLR) Clearance Review",
  "(CHC) Cultural Heritage Commission",
  "(DIR) Director of Planning",
  "(ENV) Environmental",
  "(PM) Parcel Map",
  "(PAR) Pre-Application Review",
  "(PS) Private Street",
  "(TT) Tentative Tract",
  "(VTT) Vesting Tentative Tract",
  "(ZA) Zoning Administration",
  // We need the values as is so the typeahead can work properly.
  // Before sending it to the API we remove the acronym when necessary.
].map((value) => ({ value: value.toUpperCase(), label: value }));

export const CASE_SUFFIX_DATA = [
  "(ADUH) Accessory Dwelling Unit Within Hpoz",
  "(ADU) Accessory Dwelling Unit",
  "(ADD(n)) Addendum for EIR",
  "(APA) Administrative Plan Approval (Wireless Projects)",
  "(AE) Adult Entertainments",
  "(AHRF) Affordable Housing Referral Form",
  "(ACI) Amendment to Council Instructions",
  "(A) Amendment",
  "(S) Animal Slaughtering District",
  "(AD) Annexation/Detachment",
  "((n)A) Appeals",
  "(AIC) Approval in Concept",
  "(ZAA) Area,Height,Yard,and Bldg Line Adjmnts Gt 20% (Slight Modifications)",
  "(BSA) Building and Safety Appeal to Za",
  "(BL) Building Line",
  "(CR) California Register",
  "(CAL) Calvo Exclusion",
  "(CC(n)) Case Clearance",
  "(CM) Case Management",
  "(CATEX) Categorical Exclusion",
  "(CE) Categorical Exemption",
  "(COA) Certificate of Appropriateness",
  "(CCMP) Certificate of Compatibility",
  "(COC) Certificate of Compliance",
  "(CLQ) Clarification of 'Q' Conditions",
  "(CDP) Coastal Development Permit",
  "(CEX) Coastal Exemption",
  "(CA) Code Amendment",
  "(CART) Commercial Artcraft District",
  "(CDO) Community Design Overlay District",
  "(CPIOA) Community Plan Implementation Overlay Adjustment",
  "(CPIO) Community Plan Implementation Overlay Amendment and Establishment",
  "(CPIOC) Community Plan Implementation Overlay Clearance",
  "(CPIOE) Community Plan Implementation Overlay Exception",
  "(CPU) Community Plan Update",
  "(CUB) Conditional Use Beverage-Alcohol",
  "(CUE) Conditional Use Exception",
  "(CUW) Conditional Use Wireless",
  "(CU) Conditional Use",
  "(CC) Condominium Conversion",
  "(CWC) Conforming Work Contributing Elements",
  "(CWNC) Conforming Work Non-Contributing Elements",
  "(CNCO) Continuation of Nonconforming Oil Wells",
  "(CASP) Cornfield Arroyo Specipic Plan",
  "(DPS) Deemed-To-Be-Approved Private Street",
  "(DEM) Demolitions",
  "(DB) Density Bonus",
  "(DRB) Design Review Board",
  "(DA) Development Agreement",
  "(DI) Director of Planning Interpretation",
  "(DD) Director's Determination",
  "(ELD) Elder Care Facilities",
  "(EAF) Environmental Assessment",
  "(EIR) Environmental Impact Report",
  "(K) Equinekeeping District",
  "(ED1) Executive Directive One",
  "(EHS) Extended Home-Sharing",
  "(FH) Fence Height District",
  "(F) Fence Height",
  "(FAR) Floor Area Ratio Averaging",
  "(GPAJ) General Plan Amendment JJJ",
  "(GPA) General Plan Amendment",
  "(GP) Grading Tract Maps",
  "(GB) Green Building",
  "(HE) Hardship Exemption",
  "(HD) Height District",
  "(HCM) Historic Cultural Monument",
  "(HPOZ) Historic Preservation Overlay Zone",
  "(HSRV) Home-Sharing Revocation",
  "(HCA) Housing Crisis Act",
  "(ICO) Interim Control Ordinance",
  "(IPRO) Interim Plan Revision Ordinance",
  "(I) Interpretations",
  "(LCP) Local Coastal Program",
  "(TV) Major Transit Verification",
  "(MCUP) Master Conditional Use Permit",
  "(MPA) Master Plan Approval",
  "(MEL) Mello Act Compliance Review",
  "(O) Methods and Conditions - Oil Drilling Cases",
  "(MAEX) Mills Act Exemption",
  "(MA) Mills Act",
  "(MSC) Miscellaneous (Policies, Guidelines, Resolutions, Etc.)",
  "(MND) Mitigated Negative Declaration",
  "(MU) Mixed Use District",
  "(MPC) Mobilehome Park Closure",
  "(M(n)) Modification",
  "(MSP) Mulholland Specific Plan",
  "(NR) National Register",
  "(ND) Negative Declaration",
  "(NSO) Neighborhood Stabilization Overlay",
  "(CN) New Condominiums",
  "(NC) Nonconforming Use Cases",
  "(NWR) Nonconforming Oil Well Review",
  "(OVR) Overlay Review",
  "(PPSP) Paramount Pictures Specific Plan",
  "(PMEX) Parcel Map Exemption",
  "(PMUL) Parcel Map Urban Lot",
  "(PMV) Parcel Map Violation",
  "(PMW) Parcel Map Waiver",
  "(PMLA) Parcel Map",
  "(POD) Pedestrian-Oriented District",
  "(PSH) Permanent Supportive Housing",
  "(PAB) Plan Approval Booze",
  "(PAD) Plan Approval Only for A Deemed-To-Be-Approved CU",
  "(PA(n)) Plan Approval",
  "(PC) Plan Consistency Appeal",
  "(PV) Playa Vista",
  "(P(n)) Preliminary Design Review",
  "(PHP) Priority Housing Project",
  "(A) Private Street Modifications (1st Request)",
  "(B) Private Street Modifications (2nd Request)",
  "(C) Private Street Modifications (3rd Request)",
  "(D) Private Street Modifications (4th Request)",
  "(E) Private Street Modifications (5th Request)",
  "(PP) Project Permit",
  "(PUB) Public Benefit",
  "(PWA) Public Works Approval",
  "(QC) Q Condition Clearance",
  "(RAO) Reasonable Accommodation Ordinance",
  "(REC(n)) Reconsideration",
  "(RDPA) Redevelopment Plan Project-Adjustment",
  "(RDP) Redevelopment Plan Project",
  "(RFA) Residential Floor Area District",
  "(RPD) Residential Planned Development District",
  {
    value: "(RBPB) Restaurant Beverage Program - Alcohol Sensitive Area",
    transform() {
      return this.value;
    },
  },
  {
    value: "(RBPA) Restaurant Beverage Program - Regular",
    transform() {
      return this.value;
    },
  },
  "(REV) Reversion to Acreage (Consolidation of Lots)",
  "(RV) Revocation",
  "(RIO) River Improvement Overlay District",
  "(WVR) Sb9/Adu Waivers",
  "(5A) Section 245 Review",
  "(SN) Sign District",
  "(SPR) Site Plan Review",
  "(SM) Slight Modifications",
  "(SLD) Small Lot Design",
  "(SL) Small Lot Subdivision",
  "(SP) Specific Plan (Including Amendments)",
  "(SPE) Specific Plan Exception",
  "(SPPA) Specific Plan Project Permit Adjustment",
  "(SPP) Specific Plan Project Permit Compliance",
  "(SPPM) Specific Plan Project Permit Modification",
  "(SE) Statutory Exemptions",
  "(SIP) Streamlined Infill Process (Sb35)",
  "(SUP(n)) Supplemental for EIR",
  "(G) Surface Gravel Mining District",
  "(SMA) Surface Mining Act",
  "(SCEA) Sustainable Communities Environmental Assessment",
  "(SCPE) Sustainable Communities Priority Exemption",
  "(TC) Technical Corrections to Historic Resources Surveys",
  "(TEQ) Temporary Earthquake",
  "(TLT) Temporary Long Term Use",
  "(TAE) Temporary Use Approvals for Emergencies",
  "(EXT(n)) Time Extension",
  "(TDR) Transfer of Development Rights (TFAR)",
  "(TOC) Transit Oriented Communities",
  "(UDU) Unapproved Dwelling Unit",
  "(UAIZ) Urban Agriculture Incentive Zone",
  "(VSO) Venice Signoff",
  "(VCU) Vesting Conditional Use",
  "(VHCA) Vesting Housing Crisis Act",
  "(VZCJ) Vesting Zone Change JJJ",
  "(VZC) Vesting Zone Change",
  "(WDI) Waiver of Dedications and Improvements",
  "(WTM) Waiver of Tract Map",
  "(WC) Warner Center",
  "(WTF) Wireless Telecom Facility",
  "(ZAD) Za Determination (Per Lamc 12.27)",
  "(ZAI) Za Interpretations",
  "(ZBA) Zone Boundary Adjustment",
  "(ZCJ) Zone Change JJJ",
  "(ZC) Zone Change",
  "(ZV) Zone Variance",
  // This list can be of strings or objects.
  // Choose object if we need some advanced or hotifx configuration.
].map((option) => {
  if (typeof option === "string") {
    return { value: option.toUpperCase(), label: option };
  } else {
    return { value: option.transform(), label: option.value };
  }
});

export const USES_DATA = [
  "Accessory",
  "ADU",
  "Automotive Repair",
  "Bar",
  "Billboard",
  "Brewery",
  "Car Wash",
  "Child Care Facility",
  "Commercial",
  "Data Center",
  "Drive-Thru",
  "Duplex",
  "Fire Station",
  "Garage",
  "Gas Station",
  "Hazardous Waste Facility",
  "Hotel",
  "Industrial",
  "Laboratory",
  "Laundry",
  "Light Industrial",
  "Medical",
  "Mixed-Use",
  "Mobile Homes",
  "Multifamily Dwelling",
  "Nursery",
  "Office",
  "Other",
  "Park",
  "Parking",
  "Recreation",
  "Religious Facility",
  "Restaurant",
  "Retail",
  "School",
  "Senior Housing/Care",
  "Single-Family Dwelling",
  "Storage",
  "Vacant",
  "Wall Sign",
  "Wireless Telecommunications Facility",
].map((value) => ({ value, label: value }));

export const CITY_DATA = [
  { value: "los_angeles", label: "Los Angeles" },
  { value: "la_county", label: "Los Angeles County" },
  { value: "santa_monica", label: "Santa Monica" },
] as const;
